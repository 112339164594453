import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import CR38TEWhiteLogo from '../../icons/cr38te-white.svg';
import MainNav from '../../components/sub-components/main-nav';

const Header = styled.header`
	display:grid;
	grid-template-columns:185px 1fr;
    grid-column-gap:${props => props.theme.padding.inset};
    padding:${props => props.theme.padding.inset};
    align-items:center;
    position:fixed;
    width:100%;
    background-color:${props => props.theme.colors[props.bgColor]};
	z-index:20;
	top:0px;
	left:0px;
	height:74px;
	@media(max-width:480px) {
		height:auto;
	}
`;

const LogoLink = styled(Link)`
	display:block;
	justify-items: center;
    display: grid;

	@media (max-width:670px) {
		justify-items: start;
	}
`;

const Image = styled.img`
	width:125px;
	justify-content: end;

	@media (max-width:670px) {
		width:75px;
		justify-content: start;
	}
`;

const HeaderArea = () => {
	return (
		<Header bgColor="defaultPrimary">
			<LogoLink to="/">
				<Image src={CR38TEWhiteLogo} alt={'CR38TE - DEFINE.DESIGN.DEVELOP'} />
			</LogoLink>
			<MainNav />
		</Header>
	);
}
export default HeaderArea;