import React from 'react';
import styled from 'styled-components';

const Footer = styled.footer`
    display:grid;
	grid-template-columns:1fr;
	grid-auto-flow: column;
	justify-items: center;
    grid-gap:${props => props.theme.padding.inset};
    background-color:${props => props.theme.colors[props.bgColor]};
	padding:${props => props.theme.padding.inset};
`;

const Meta = styled.p`
    font-size:12px;
	line-height:24px;
	color:${props => props.theme.colors[props.fontColor]};
	font-family: ${props => props.theme.fonts.secondFont};
    font-weight:${props => props.theme.fontWeight.regular};
    text-align:center;
	margin:0px;
	text-transform:uppercase;
`;

const HyperLink = styled.a`
	color:${props => props.theme.colors[props.fontColor]};
	font-weight:${props => props.theme.fontWeight.bold};
	text-decoration:none;
	margin-right:${props => props.theme.padding.inset};
`;

const FooterArea = () => {
	return (
		<Footer bgColor="defaultPrimary">
			
			<Meta fontColor="defaultSecondary">
				<HyperLink fontColor="defaultSecondary" href="/sitemap.xml" target="_blank">Sitemap</HyperLink>
				&copy;{` copyright ${new Date().getFullYear()} CR38TE`}
			</Meta>
		</Footer>
	);
}
export default FooterArea;