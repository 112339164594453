import React from 'react';
import styled from 'styled-components';
import CR38TEWhiteLogo from '../../icons/cr38te-white.svg';
import MainNav from '../sub-components/main-nav';

const Aside = styled.aside`
    display:grid;
	grid-template-columns:1fr 1fr 1fr;
    grid-column-gap:${props => props.theme.padding.inset};
    padding:${props => props.theme.padding.inset};
    align-items:center;
    width:100%;
    background-color:${props => props.theme.colors[props.bgColor]};
	z-index:20;
	padding-top:${props => props.theme.padding.doubleInset};
	padding-bottom:${props => props.theme.padding.doubleInset};
	
	@media (max-width:1024px) {
		justify-items:center;
		grid-template-columns: 1fr;
		> nav {
			display:none;
			visibility:hidden;
		}
	}
`;

const Logo = styled.div`
	justify-items: start;
    display: grid;
	padding-left:30px;

	@media(max-width:1024px) {
		padding-left:0;
		margin-bottom:30px;
	}
`;

const NewsLetter = styled.div`
	color:#fff;
	text-align:center;
`;

const LogoImage = styled.img`
	width:125px;
`;

const Form = styled.form`
	position:relative;
`;

const Input = styled.input`
	border: 2px solid #ffffff;
	border-radius:15px;
	background-color:#000000;
	color:#ffffff;
	width: 100%;
	font-size: 13px;
	line-height: 26px;
	font-weight: 400;
	padding:0 167px 0 15px;
	height:36px;

	::placeholder {  
		color:#ffffff;
	}

	@media(max-width:1345px) {
		margin-bottom:15px;
	}
`;

const Button = styled.button`
	cursor:pointer;
    background-color:${props => props.theme.colors.defaultSecondary};
    color:${props => props.theme.colors.defaultPrimary};
    padding:0 45px;
	text-transform:uppercase;
	font-family:${props => props.theme.fonts.firstFont};
	font-size:13px;
	line-height:20px;
    transition:all 0.3s linear;
	margin-left:15px;
	position:absolute;
	right: 36px;
	top: 2px;
	border-radius: 0 15px 15px 0;
	right: 2px;
	border: 3px solid #000;
	height:32px;

    
    &:hover {
        background-color:${props => props.theme.colors[props.bgHoverColor]};
        color:${props => props.theme.colors[props.fontHoverColor]};
    }
	@media(max-width:1345px) {
		margin-left:15px;
	}
	@media(max-width:480px) {
		margin-left:0;
		right:0px;
	}
`;

const AsideArea = () => {
	return (
		<Aside bgColor="defaultPrimary">
			<Logo>
				<LogoImage src={CR38TEWhiteLogo} alt={'CR38TE - DEFINE.DESIGN.DEVELOP'} />
			</Logo>
			<NewsLetter>
				<Form className="js-cm-form" id="subForm" action="https://www.createsend.com/t/subscribeerror?description=" method="post" data-id="92D4C54F0FEC16E5ADC2B1904DE9ED1ACFDBCD61155F4AB72C3310D932FF5EFCF30E38DC688439589AC42042035CEC27B5E6E4E38ABF4EF3E3DFA22EBA33348A">
					<Input autocomplete="Email" aria-label="Email" className="js-cm-email-input qa-input-email" id="fieldEmail" maxlength="200" name="cm-jljtci-jljtci" required="" type="email" placeholder="Email..." />
					<Button type="submit">Subscribe</Button>
				</Form>
			</NewsLetter>
			<MainNav />
		</Aside>
	);
}
export default AsideArea;