import React from 'react';
import styled from 'styled-components';

const FullColumnItem = styled.div`
    display:grid;
    grid-template-columns:1fr;
    align-items:${props => [props.verticalAlign]};
    text-align:${props => [props.horizontalAlign]};
`;

const SectionTitle = styled.h2`
    color: ${props => props.theme.colors[props.fontColor]};
    text-transform:uppercase;
    line-height: 52px;
    font-size: 51px;
    font-family:${props => props.theme.fonts.firstFont};
    margin-top:calc( ${props => props.theme.margin.tripleInset} * 2 );

    @media(max-width:480px) {
        margin-top: ${props => props.theme.margin.doubleInset};
        font-size:26px;
        line-height:33px;
    }
`;

const TitleItem = ({
    title,
    horizontalAlign,
    verticalAlign
}) =>
    <FullColumnItem horizontalAlign={horizontalAlign} verticalAlign={verticalAlign}>
        <SectionTitle fontColor="defaultPrimary">{title}</SectionTitle>
    </FullColumnItem>
export default TitleItem;