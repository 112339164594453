import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display:grid;
    grid-template-columns:75px 1fr 2fr 75px;
    margin-top:${props => props.theme.margin.tripleInset};
    padding-top:${props => props.theme.padding.tripleInset};
    grid-column-gap:15px;

    @media(max-width:786px) {
        grid-template-columns:15px 1fr 15px;
        margin-top:${props => props.theme.margin.doubleInset};
        padding-top:${props => props.theme.padding.doubleInset};
        grid-column-gap:0;
    }
`;

const LeftColumn = styled.div`
    grid-column:2/3;
    background-color:${props => props.theme.colors[props.bgColor]};
    display:grid;
    align-items:center;
    justify-content:center;
    padding-top:${props => props.theme.padding.tripleInset};
    padding-bottom:${props => props.theme.padding.tripleInset};

    @media(max-width:786px) {
        grid-column:2/3;
        margin-bottom:8px;
    }
`;

const RightColumn = styled.div`
    grid-column:3/4;
    display:grid;
    grid-template-columns:1fr 1fr;
    grid-template-rows:1fr 1fr;
    grid-column-gap:15px;
    grid-row-gap:15px;

    @media(max-width:786px) {
        grid-column:2/3;
        grid-column-gap:8px;
        grid-row-gap:8px;
    }
`;

const Image = styled.img``;

const Images = styled.img`
    display:block;
    max-width:100%;
`;

const GallerySection = (props) => {
    const { leftColumnImage, rightColumnImages } = props;
    return (
        <Container>
            <LeftColumn bgColor="defaultPrimary">
                <Image src={leftColumnImage.sizes.fullscreen} alt="CR38TE" />
            </LeftColumn>
            <RightColumn>
                {rightColumnImages && rightColumnImages.map(({sizes, alt}, i) =>
                    <Images key={`img-${i}`} src={sizes.large} alt={alt} />
                )}
            </RightColumn>
        </Container>
    );
}
export default GallerySection;