import React from 'react';
import styled from 'styled-components';

const Block = styled.div`
    grid-column:${props => props.positionCol === 'left' ? '2/3' : '3/4'};
    align-self:${props => [props.verticalAlign]};
    justify-self:${props => [props.horizontalAlign]};
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 35px;
    height: 0;
    overflow: hidden;
    width:100%;
    height:auto;
`;

const IframeYoutube = styled.iframe`
    border:0;
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
`;

const DuoColumnYTVideoItem = ({
    verticalAlign,
    horizontalAlign,
    positionCol,
    youtubeID,
}) =>
    <Block
        positionCol={positionCol}
        verticalAlign={verticalAlign}
        horizontalAlign={horizontalAlign}
    >
        <IframeYoutube
            title="youtube video - CR38TE - DEFINE - DESIGN - DEVELOP - ARUBA"
            id="ytplayer"
            type="text/html"
            width="640px"
            height="360px"
            src={`https://www.youtube.com/embed/${youtubeID}?autoplay=0&origin=https://cr38te.com&controls=controls&loop=0&modestbranding=0`}
            frameborder="0"
        />
    </Block>
export default DuoColumnYTVideoItem;