import React, { Component } from 'react';
import {
    BrowserRouter as Router,
    Route,
} from 'react-router-dom';
import Theme from './theme';
import styled, { createGlobalStyle } from 'styled-components';
import ServerAPI from './server';
import ScrollToTop from '../src/components/sub-components/scroll-to-top';
import PortfolioItemPage from '../src/pages/single-work';
import Header from '../src/components/header';
import Aside from '../src/components/aside';
import Footer from '../src/components/footer';
import { PageRoutes } from './components/page';

const GlobalStyles = createGlobalStyle`
	*,
	*:after,
	*:before {
		box-sizing:border-box;
	}
    body {
        margin:0;
		padding:0;
        color: ${props => props.theme.colors.defaultPrimary};
        background-color: ${props => props.theme.colors.defaultSecondary};
		font-size: ${props => props.theme.fontSizes.small};
		font-weight: ${props => props.theme.fontWeight.regular};
		font-display:fallback;
	}
	h1, h2, h3, h4, h5, h6, p {
		margin-top:0;
		margin-bottom:${props => props.theme.margin.inset};
	}
	h1, h2, h3, h4, h5, h6, 
	nav > ul > li > a {
        font-family: ${props => props.theme.fonts.firstFont};
	}
	p, 
	ul > li,
	ul > li > a, 
	small, 
	em,
	strong {
		font-family: ${props => props.theme.fonts.secondFont};
	} 
`;

const Container = styled.div`
	display:grid;
	justify-content:center;
	grid-template-columns:1fr;
`;

export default class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageList: []
        }
    }

    serverAPI = new ServerAPI();

    async componentDidMount() {
        try {
            const website = await this.serverAPI.getPostJSONData('website-info', 'website-info');
            this.setState({ website, error: undefined });
        }
        catch (error) {
            this.setState({ error });
        }
        finally {
            this.setState({ loading: false });
        }
    }
    
    render() {
        const {
            website = {},
        } = this.state;
        return (
            <Theme>
                <Container>
                    <Router>
                        <GlobalStyles />
                        <ScrollToTop />
                        <Header {...website} />
                        <PageRoutes website={website} serverAPI={this.serverAPI} />
                        <Route path="/portfolio/:slug" render={(props) => <PortfolioItemPage serverAPI={this.serverAPI} {...props} />} exact />
                        <Aside />
                        <Footer />
                    </Router>
                </Container>
            </Theme>
        );
    }
} 