import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display:grid;
    grid-template-columns:1fr 2fr 1fr;

    @media(max-width:786px) {
        grid-template-columns:15px 2fr 15px;
    }
`;

const Button = styled.a`
    grid-column:2/3;
    justify-self:${props => [props.alignButton]};
    text-decoration:none;
    margin:30px 0 120px 0;

    cursor:pointer;
    background-color:${props => props.theme.colors[props.bgColor]};
    color:${props => props.theme.colors[props.fontColor]};
    padding:15px 45px;
	text-transform:uppercase;
	font-family:${props => props.theme.fonts.firstFont};
	font-size:13px;
	line-height:20px;
	border:0;
    border-radius:0;
    transition:all 0.3s linear;
    
    &:hover {
        background-color:${props => props.theme.colors[props.bgHoverColor]};
        color:${props => props.theme.colors[props.fontHoverColor]};
    }
`;

export default function ButtonContentBlock({ buttonText, isEmailButton, buttonLink, buttonEmail, alignButton }) {
    return (
        <Container>
            <Button
                bgColor="defaultPrimary"
                bgHoverColor="defaultSecondary"
                fontColor="defaultSecondary"
                fontHoverColor="defaultPrimary"
                alignButton={alignButton}
                href={isEmailButton === 'no' ? buttonLink : `mailto:${buttonEmail}`}
            >
                {buttonText}
            </Button>
        </Container>
    );
}