import React from 'react';
import HeroArea from './hero-area';
import TitleArea from './title';
import ParagraphArea from './paragraph';
import GalleryArea from './gallery';
import DuoContentArea from './duo-content-section';
import TeamMember from './team-member';
import ServicesBlocks from './services';
import ButtonContentBlock from './button-content-block';
import Logos from './partnerLogos';
import PortfolioItem from './portfolio-item';
import CallToActions from './call-to-actions';
import FullColumnArea from './fullColumnArea';
import TwoColumnArea from './duo-content';
import ContactInfoSection from './contact-info-section';
import GoogleMaps from './googleMaps';

const contentBlocksTemplates = {
    'heroArea': HeroArea,
    'titleContentBlock': TitleArea,
    'singleTextContentBlock': ParagraphArea,
    'galleryContentBlock': GalleryArea,
    'duoContentBlock': DuoContentArea,
    'chooseMembers': TeamMember,
    'servicesBlocks': ServicesBlocks,
    'buttonContentBlock': ButtonContentBlock,
    'chooseLogos': Logos,
    'chooseWork': PortfolioItem,
    'callToActionSection': CallToActions,
    'fullColumn': FullColumnArea,
    'twoColumns': TwoColumnArea,
    'contactInfoSection': ContactInfoSection,
    'googleMaps': GoogleMaps,
}

export default function ContentBlocks({ contentBlocks, className }) {
    return contentBlocks && contentBlocks.map(({ acf_fc_layout, ...props }, i) => {
        const Template = acf_fc_layout ? contentBlocksTemplates[acf_fc_layout] : contentBlocksTemplates['heroArea'];
        return (
            <Template key={`dynamic-section-${i}`} {...props} className={className} />
        )
    })
}