import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display:grid;
    grid-template-columns:1fr 2fr 1fr;

    &.vacancy {
        grid-template-columns: 1fr 1fr 1fr;
        h2 {
            text-align:left;
            padding-bottom:${props => props.theme.padding.inset};

            @media(max-width:480px) {
                text-align:left;
                font-size:36px
            }
        }
        @media(max-width:480px) {
            grid-template-columns: 15px 1fr 15px;
        }
    }

    @media(max-width:786px) {
        grid-template-columns:15px 2fr 15px;
    }
`;

const Title = styled.h2`
    grid-column:2/3;
    text-align:${props => [props.alignText]};
    margin-top:${props => props.theme.margin.tripleInset};
    margin-bottom:0;
    padding-top:${props => props.theme.padding.tripleInset};
    padding-bottom:${props => props.theme.padding.tripleInset};
    font-size:${props => props.theme.fontSizes.large};
    text-transform:uppercase;

    @media(max-width:786px) {
        margin-top:${props => props.theme.margin.doubleInset};
        padding-top:${props => props.theme.padding.doubleInset};
        padding-bottom:${props => props.theme.padding.doubleInset};
    }
`;

export default function TitleSection({ title, alignText, className }) {
    return (
        <Container className={className}>
            <Title alignText={alignText}>{title}</Title>
        </Container>
    );
}
