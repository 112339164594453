import React, { useEffect, useState, /*useState*/ } from 'react';
import styled from 'styled-components';
import Button from '../button';

const Container = styled.div`
    display:grid;
    grid-template-columns:1fr;
    align-items:center;
    width:100%;
    background-position:top center;
    background-repeat:no-repeat;
    background-size:cover;
    background-attachment:fixed;
    height: calc( ${props => [props.height]} - 74px );
    min-height:550px;

    @media (max-width:670px) {
        background-position:center center;
    }
    @media(max-width:480px) {
        background-position:center center;
        background-size:auto;
        background-attachment:scroll;
    }
`;

const Content = styled.div`
    display:grid;
    grid-template-columns:150px 650px 1fr;

	@media (max-width:1024px) {
        grid-template-columns:30px 1fr 30px;
    }
    @media(max-width:480px) {
        grid-template-columns:15px 1fr 15px;
    }
`;

const Title = styled.h2`
    color:${props => props.theme.colors[props.fontColor]};
    font-size:${props => props.theme.fontSizes.small};
    text-transform: uppercase;
    text-shadow: 0px 2px 5px #0000004B;
    letter-spacing: 1px;
    grid-column:2 / 3;
`;

const SubTitle = styled.h3`
    font-size:${props => props.theme.fontSizes.large};
    text-transform:uppercase;
    grid-column:2 / 3;
    align-content:center;

    @media (max-width:670px) {
        font-size:${props => props.theme.fontSizes.medium};
	}
`;

const NewButton = styled(Button)`
    grid-column:2 / 3;
    width:195px;
`;

const Mark = styled.div`
    color:${props => props.theme.colors[props.fontColor]};
    background-color:${props => props.theme.colors[props.bgColor]};
    line-height:52px;
    font-size:51px;
    width:auto;
    display:inline-block;
    overflow:hidden;
    padding:14px 10px 0 10px;
    margin-bottom:${props => props.theme.margin.inset};
    align-items:center;
    margin-right:10%;

    @media (max-width:1024px) {
        margin-right:17%;
    }

    @media (max-width:670px) {
        font-size:25px;
        padding-top:0;
        margin-right:30px;
	}
`;

const Hero = ({
    title,
    backgroundImage,
    mobileBackgroundImage,
    titleTop,
    titleBottom,
    buttonLink,
    buttonTitle,
    color,
    heroAreaHeight,
}) => {
    const [imageUrl, setImageUrl] = useState('');

    const handleWindowResize = () => {
        setImageUrl(window.innerWidth >= 480 ? backgroundImage?.sizes?.fullscreen ?? '' : mobileBackgroundImage?.sizes?.fullscreen ?? '');
     };

    window.addEventListener('resize', handleWindowResize);
    useEffect(() => {
        setImageUrl(window.innerWidth >= 480 ? backgroundImage?.sizes?.fullscreen ?? '' : mobileBackgroundImage?.sizes?.fullscreen ?? '');
    }, [backgroundImage, mobileBackgroundImage]);
    
    return (
        <Container height={heroAreaHeight} style={{ backgroundImage: `url(${imageUrl})` }}>
            <Content>
                <Title fontColor="defaultSecondary">{title}</Title>
                <SubTitle>
                    <Mark
                        bgColor={color === 'darkmode' ? 'defaultPrimary' : 'defaultSecondary'}
                        fontColor={color === 'darkmode' ? 'defaultSecondary' : 'defaultPrimary'}
                    >
                        {titleTop}
                    </Mark>
                    {
                        titleBottom &&
                        <Mark
                            bgColor={color === 'darkmode' ? 'defaultPrimary' : 'defaultSecondary'}
                            fontColor={color === 'darkmode' ? 'defaultSecondary' : 'defaultPrimary'}
                        >
                            {titleBottom}
                        </Mark>
                    }
                </SubTitle>
                {buttonLink &&
                    <NewButton
                        buttonLink={buttonLink}
                        bgcolor="defaultPrimary"
                        bgcolorhover="defaultSecondary"
                        fontcolor="defaultSecondary"
                        fontcolorhover="defaultPrimary"
                        buttonTarget="_self"
                        buttonText={buttonTitle}
                    />
                }
            </Content>
        </Container>
    );
}
export default Hero;