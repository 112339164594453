import React from 'react';
import FullScreenBackgroundImage from '../sub-components/fullScreenBackgroundImage';
import TitleSection from '../sub-components/titleSection';

const FullColumnSection = ({ dynamicContentBlocks, serverAPI }) =>
    <>
        {
            dynamicContentBlocks &&
            dynamicContentBlocks.map(({ acf_fc_layout, ...props }, i) =>
                <React.Fragment key={`dynamic-section-${i}`}>
                    {acf_fc_layout === 'fullscreenBackgroundImage' && <FullScreenBackgroundImage {...props} serverAPI={serverAPI}/>}
                    {acf_fc_layout === 'titleSection' && <TitleSection {...props} />}
                </React.Fragment>
            )
        }
    </>
export default FullColumnSection