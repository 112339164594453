import React from 'react';
import styled from 'styled-components';
import ContentBlocks from '../components/content-blocks';

const Main = styled.main`
	display:grid;
	grid-template-columns:1fr;
    margin-top:74px;
	align-items:end;

	@media (max-width:670px) {
		margin-top:55px;
	}
`;

export default function Work({ acf }) {
    let { contentBlocks } = acf;
    return (
        <Main>
            <ContentBlocks contentBlocks={contentBlocks} />
        </Main>
    );
}