import React from 'react';
import styled from 'styled-components';
import ContentBlocks from '../components/content-blocks';

const Main = styled.main`
	display:grid;
	grid-template-columns:1fr;
    margin-top:74px;
	align-items:end;
	/* WRAP HEROS INTO ONE DIV */
	/* overflow-y:scroll;
	height:calc( 100vh - 74px );
	scroll-snap-type: mandatory;
    scroll-snap-points-y: repeat(3rem);
    scroll-snap-type: y mandatory; */

	@media (max-width:670px) {
		margin-top:55px;
	}
	/* > div {
		scroll-snap-align: start;
	} */
`;

const Home = ({ acf }) => {
    const { contentBlocks } = acf;
    return (
        <Main repeatItems={contentBlocks.length}>
            <ContentBlocks contentBlocks={contentBlocks} />
        </Main>
    );
}
export default Home;