import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { BASE_URL } from '../../server';

const Button = styled(Link)`
    font-family:${props => props.theme.fonts.firstFont};
    font-size:${props => props.theme.fontSizes.small};
    color:${props => props.theme.colors[props.fontcolor]};
    background-color:${props => props.theme.colors[props.bgcolor]};
    padding:20px 45px;
    line-height:20px;
    text-decoration:none;
    text-transform:uppercase;
    text-align:center;
    transition:all 0.2s linear;

    &:hover {
        color:${props => props.theme.colors[props.fontcolorhover]};
        background-color:${props => props.theme.colors[props.bgcolorhover]};
    }

    @media (max-width:1024px) {
        font-size:15px;
    }
`;

const Buttons = ({
    className = '',
    fontcolor = 'defaultPrimary',
    fontcolorhover = 'defaultPrimary',
    bgcolor = 'defaultTertiary',
    bgcolorhover = 'defaultTertiary',
    buttonTarget = '_self',
    buttonLink,
    buttonText = 'Button Title'
}) => {
    return (
        <Button
            className={className}
            fontcolor={fontcolor}
            fontcolorhover={fontcolorhover}
            bgcolor={bgcolor}
            bgcolorhover={bgcolorhover}
            to={buttonLink?.url?.replace(BASE_URL, '') || '/'}
            target={buttonTarget}
            title={`${buttonText} - CR38TE - DEFINE - DESIGN - DEVELOP`}
        >
            {buttonText}
        </Button>
    );
}
export default Buttons;
