import React from 'react';
import styled from 'styled-components';
import PortfolioSingleItem from '../sub-components/portfolioSingleItem';

const MainContainer = styled.div`
	margin-top:15px;

    &.main-container-1 {
        margin-top:120px;

        @media(max-width:480px) {
            margin-top:60px;
        }
    }

    @media(max-width:900px) {
        margin-top:0;
    }
`;

const Container = styled.div`
    display:grid;
    grid-template-columns:150px 1fr 2fr 300px;
    margin-bottom:0;
    grid-column-gap:15px;

    @media(max-width:1440px) {
        grid-template-columns:75px 1fr 2fr 75px;
    }

    @media(max-width:1140px) {
        grid-template-columns:15px 175px 2fr 15px;
        grid-column-gap:0;
    }
    @media(max-width:480px) {
        margin-bottom:${props => props.theme.margin.inset};
    }
`;

const SubContainer = styled.div`
    grid-column:2/4;
    display:grid;
    grid-template-columns:150px 3fr;
    margin-bottom:0;

    @media(max-width:1140px) {
        grid-template-columns:125px 1fr;
    }

    @media(max-width:900px) {
        grid-template-columns:1fr;
    }
`;

const TitleContainer = styled.div`
    grid-column:1/2;
    margin-top:0px;

    @media(max-width:900px) {
        margin-top:0px;
        padding-right:0;
        position: sticky;
        top: 55px;
        z-index: 19;
        background-color:${props => props.theme.colors.defaultSecondary};
    }
`;

const Title = styled.h2`
    font-size: ${props => props.theme.fontSizes.small};
    line-height:28px;
    text-transform: uppercase;
    letter-spacing: 1px;
    position:sticky;
    top:100px;
`;

const WorkContainer = styled.div`
    grid-column:2/3;
    text-align:justify;
    line-height:28px;
    display:grid;
    grid-template-columns:1fr 1fr;
    grid-column-gap:15px;

    @media(max-width:900px) {
        grid-template-columns:1fr 1fr;
    }
    
    @media(max-width:900px) {
        margin-top:0px;
        padding-left:0;
        grid-column:1/3;
    }

    @media(max-width:700px) {
        grid-template-columns:1fr;
    }
`;

export default function PortfolioItem({ title, workItem }) {
    return (
        <MainContainer className={`main-container-${workItem.ID}`}>
            <Container>
                <SubContainer>
                    <TitleContainer>
                        <Title>{title}</Title>
                    </TitleContainer>
                    <WorkContainer>
                        {workItem?.length > 0 && workItem.map((w, i) => <PortfolioSingleItem key={`portfolio-${i}`} {...w} />)}
                    </WorkContainer>
                </SubContainer>
            </Container>
        </MainContainer>
    );
}