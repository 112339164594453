import React from 'react';
import { ThemeProvider } from 'styled-components';

const theme = {
    colors: {
        defaultPrimary      : "#000000", // black
        defaultSecondary    : "#ffffff", // white
        defaultTertiary     : "#00000029", // lightgrey
    },
    boxShadow: {
        greyShadow  : '0px 2px 3px #00000029',
        noShadow    : 'none'
    },
    fonts: {
        firstFont   : "itc-avant-garde-gothic-pro", 
        secondFont  : "open-sans"

    },
    fontWeight: {
        thin    : "100",
        light   : "300",
        regular : "400",
        medium  : "500",
        bold    : "700",
        black   : "900"
    },
    fontSizes: {
        small   : "14px",
        medium  : "35px",
        large   : "52px"
    },
    padding: {
        inset       : "15px",
        doubleInset : "30px",
        tripleInset : "60px"
    },
    margin: {
        inset       : "15px",
        doubleInset : "30px",
        tripleInset : "60px"
    }
}

const Theme = ({ children }) => (
    <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme; 