import React from 'react';
import styled from 'styled-components';

const Block = styled.div`
    grid-column:${props => props.positionCol === 'left' ? '2/3' : '3/4'};
    align-self:${props => [props.verticalAlign]};
    justify-self:${props => [props.horizontalAlign]};

    @media(max-width:480px) {
        grid-column:2/3;
    }
`;

const Video = styled.video`
    width:${props => [props.width]};
    height:${props => [props.height]};

    @media(max-width:480px) {
        width:100%;
    }
`;

const Source = styled.source``;

const DuoColumnHostedVideoItem = ({
    verticalAlign,
    horizontalAlign,
    positionCol,
    video
}) =>

    <Block
        positionCol={positionCol}
        verticalAlign={verticalAlign}
        horizontalAlign={horizontalAlign}
    >
        <Video width={video.width} height={video.height} autoPlay muted loop>
            <Source src={video.url} type={video.mime_type} />
                    Sorry, your browser doesn't support embedded videos.
                </Video>
    </Block>
export default DuoColumnHostedVideoItem;