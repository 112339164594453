import React from 'react';
import styled from 'styled-components';

const Block = styled.div`
    grid-column:${props => props.positionCol === 'left' ? '2/3' : '3/4'};
    align-self:${props => [props.verticalAlign]};
    justify-self:${props => [props.horizontalAlign]};

    @media(max-width:480px) {
        grid-column:2/3;
    }
`;

const SectionParagraph = styled.p`
    grid-column:3/4;
    font-size:16px;
    font-family:${props => props.theme.fonts.secondFont};
    font-weight:${props => props.theme.fontWeight.thin};
    line-height:32px;
    margin-bottom:30px;
    width:500px;

    @media(max-width:480px) {
        width:100%;
    }
`;

const DuoColumnParagraphItem = ({
    verticalAlign,
    horizontalAlign,
    sectionParagraph,
    positionCol
}) =>
    <Block
        positionCol={positionCol}
        verticalAlign={verticalAlign}
        horizontalAlign={horizontalAlign}
    >
        <SectionParagraph dangerouslySetInnerHTML={{ __html: `${sectionParagraph}` }} />
    </Block>
export default DuoColumnParagraphItem;