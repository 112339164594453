import React from 'react';
import styled from 'styled-components';
import Preloader from '../preloader/preloader.gif';

const Container = styled.div`
    background-color:${props => props.theme.colors.defaultPrimary};
    height:calc( 100vh - 74px );
    margin-top:74px;
    width:100%;
    display:grid;
    grid-template-columns:1fr;
    grid-template-rows:1fr;
    align-items:center;
    justify-content:center;

    @media (max-width:670px) {
		margin-top:55px;
	}
`;

const Image = styled.img`
    width: 25px;
    height: 25px;
    display: block;
    margin: 0 auto;
`;

const PageLoading = () => {
    return (
        <Container>
            <Image src={Preloader} alt="preloader Metafund" />
        </Container>
    );
}
export default PageLoading;
