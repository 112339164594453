import React from 'react';
import styled from 'styled-components';
import TeamMemberItem from '../sub-components/teamMemberItem';

const Container = styled.div`
    display:grid;
    grid-template-columns:75px repeat(3, 1fr) 75px;       

    @media(max-width:736px) {
        grid-template-columns:15px repeat(3, 1fr) 15px;
    }

    @media(max-width:480px) {
        padding-top:60px;
        padding-bottom:60px;
        grid-template-columns:15px repeat(3, 1fr) 15px;
    }
`;

const Members = styled.div`
    grid-column:2/5;
    display:grid;
    grid-template-columns:repeat(3, 1fr);
    grid-column-gap:${props => props.theme.padding.inset};

    @media(max-width:480px) {
        grid-template-columns:repeat(1, 1fr);
    }
`;

export default function TeamMember({ members }) {
    return (
        <Container>
            <Members>
                {members && members.map((member, i) => <TeamMemberItem key={`member-${i}`} {...member} />)}
            </Members>
        </Container>
    );
}