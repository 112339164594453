import React, { Component } from 'react';
import styled from 'styled-components';
import Form from './form';
import { Paragragh } from '../../contact-info-section';

const FormContainer = styled.div`
    grid-column:2/3;
    padding:120px 120px 120px 120px;

    @media (max-width:1024px) { 
        padding: 60px 0 30px 0;
    }

    @media (max-width:670px) {
        grid-column: 1/-1;
        padding: 60px 15px 30px 15px;
        grid-row: 1/2;
    }
`;

const ContactMeta = styled.div`
    margin-bottom:${props => props.theme.margin.doubleInset};
`;

const MainTitle = styled.h1`
    color:${props => props.theme.colors[props.fontColor]};
    font-size: 32px;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom:0;
`;

export default class ContactForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            registrationLoading: false,
            showFormError: false,
            showFormSuccess: false,
        }
    }

    onRegister = async (results) => {
        let errors = results.map(r => r.error).filter(e => e != null);
        if (errors.length > 0) {
            console.log(`${errors.length} errors`);
            this.setState({ showFormError: true, showFormSuccess: false });
        }
        else {
            const grecaptcha = window.grecaptcha;
            if (!grecaptcha) {
                console.log(`recaptcha object not defined`);
                return;
            }
            const self = this;
            try {
                let reCaptchaAction = 'contactpage';
                grecaptcha.ready(function () {
                    grecaptcha.execute('6LckHf8UAAAAAPxzVqynTU1YTwpLi0tVmktoPlQw', { action: reCaptchaAction }).then(function (token) {

                        let { ajaxurl, ajax_nonce } = window.ajaxVars || {};
                        if (!ajaxurl || !ajax_nonce) {
                            console.log(`incorrect nonce or url ${ajax_nonce}, ${ajaxurl}`);
                            self.setState({ showFormError: true, showFormSuccess: false });
                            return;
                        }
                        self.setState({ showFormError: false, showFormSuccess: true });

                        let info = results.reduce((dict, entry) => {
                            dict[entry.id] = entry.value;
                            return dict;
                        }, {});
                        let allResults = { ...info };

                        let formData = new FormData();
                        for (const property in allResults) {
                            formData.set(property, allResults[property]);
                        }
                        formData.set('action', 'handle_contact_form');
                        formData.set('security', ajax_nonce);
                        formData.set('token', token);
                        formData.set('recaptcha-action', reCaptchaAction);
                        fetch(ajaxurl, {
                            method: 'POST',
                            body: formData
                        })
                            .then(response => {
                                let result = response.json();
                                console.log(result);
                                // window.location.href = '/thank-you';
                            })
                            .then(async (responseJson) => {
                                console.log(responseJson);
                            })
                            .catch((error) => {
                                console.error(error);
                            })
                            .finally(_ => {
                                self.setState({ validating: false });
                            });
                    });
                });
            }
            catch (error) {
                console.log('Error executing grecaptcha');
                console.log(error);
            }
        }
    }

    render() {
        let { showFormError, showFormSuccess, registrationLoading } = this.state;
        let { description, title, } = this.props;
        return (
            <FormContainer>
                <ContactMeta>
                    {title && <MainTitle fontColor="defaultPrimary">{title}</MainTitle>}
                    {description && <Paragragh fontColor="defaultPrimary">{description}</Paragragh>}
                </ContactMeta>
                <Form
                    register={this.onRegister}
                    info={this.info}
                    showFormError={showFormError}
                    showFormSuccess={showFormSuccess}
                    registrationLoading={registrationLoading}
                    {...this.props}
                />
            </FormContainer>
        );
    }
}