import React, { Component } from 'react';
import styled from 'styled-components';
import {
    Validator,
    TextField,
    TextAreaField,
    ValueSetValidator,
    EmailValidator,
} from '../../../input';

const Form = styled.form`
    display:grid;
    grid-template-columns:1fr;
    grid-template-rows:1fr 250px 80px;
    grid-row-gap:${props => props.theme.padding.doubleInset};

    input, 
    textarea {
        border:0;
		border-bottom:2px solid ${props => props.theme.colors.defaultPrimary};
		width:100%;
        font-size:13px;
        line-height:26px;
        font-weight:${props => props.theme.fontWeight.regular};
        font-family:${props => props.theme.fonts.secondFont};
        border-radius:0;
    }
    textarea {
		border-radius:0;
		height:200px;
    }
    
    span.Error {
		color: #721c24;
        background-color: #f8d7da;
        font-family:${props => props.theme.fonts.firstFont};
        font-size:12px;
        padding:10px;
        display:block;
    }
    
    @media(max-width:1024px) {
        padding-right:15px;
    }
`;

const Feedback = styled.div`
    color: ${props => [props.fontColor]};
    background-color: ${props => [props.bgColor]};
    font-family:${props => props.theme.fonts.firstFont};
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 30px;
    border-radius:0;
    grid-column:1/-1;
`;

const InputContainerHolder = styled.div`
    grid-row:1/2;
    grid-column:1/-1;
    display:grid;
    grid-template-columns:1fr 1fr;
    grid-column-gap:${props => props.theme.padding.tripleInset};

    @media(max-width:1024px) {
        grid-column-gap:${props => props.theme.padding.inset};
    }
`;

const MessageHolder = styled.div`
    grid-row:2/3;
    grid-template-columns:1fr;
`;

const ButtonHolder = styled.div`
     grid-row:3/4;
     grid-template-columns:1fr;
`;

const InputContainer = styled.div`
    margin-bottom:${props => props.theme.padding.doubleInset};
    width:100%;
`;

const Label = styled.label`
    font-family:${props => props.theme.fonts.firstFont};
    font-weight:bold;
    letter-spacing:0.3px;
    margin-bottom:${props => props.theme.padding.inset};
    display:block;
`;

const TextContainer = styled.div`
    grid-row:2/3;
    grid-column:1/-1;
`;

const Button = styled.button`
    cursor:pointer;
    background-color:${props => props.theme.colors[props.bgColor]};
    color:${props => props.theme.colors[props.fontColor]};
    padding:15px 45px;
	text-transform:uppercase;
	font-family:${props => props.theme.fonts.firstFont};
	font-size:13px;
	line-height:20px;
	border:0;
    border-radius:0;
    transition:all 0.3s linear;
    
    &:hover {
        background-color:${props => props.theme.colors[props.bgHoverColor]};
        color:${props => props.theme.colors[props.fontHoverColor]};
    }
`;

class ContactForm extends Component {

    constructor(props) {
        super(props);
        this.validator = new Validator();
    }

    register = e => {
        if (this.props.register) {
              this.props.register(this.validator.validResult());
        }
        e.preventDefault();
    }

    render() {
        let { 
            showFormError, 
            showFormSuccess, 
            registrationLoading,
            fullNamePlaceholder,
            lastNamePlaceholder,
            emailPlaceholder,
            telephonePlaceholder,
            companyPlaceholder,
            subjectPlaceholder,
            messagePlaceholder,
            buttonTitle,        
        } = this.props;
        showFormError = showFormError || false
        showFormSuccess = showFormSuccess || false;

        let sendStatus = 'initial';
        if (registrationLoading) sendStatus = 'processing';
        else if (showFormSuccess) sendStatus = 'done';

        return (
            <React.Fragment>
                {showFormError &&
                    <Feedback bgColor="#f8d7da" fontColor="#721c24">There are some fields missing or incorrect. Please check them and try again.</Feedback>
                }
                {showFormSuccess &&
                    <Feedback bgColor="#d1ecf1" fontColor="#0c5460">Thank you for your filling in the contact form. We will contact you as soon as possible.</Feedback>
                }
                <Form>
                    <InputContainerHolder>
                        <InputContainer>
                            <Label htmlFor="firstName">First Name *</Label>
                            <TextField
                                id='firstName'
                                placeholder={fullNamePlaceholder}
                                validator={new ValueSetValidator(this.validator)}
                            />
                        </InputContainer>
                        <InputContainer>
                            <Label htmlFor="lastName">Last Name *</Label>
                            <TextField
                                id='lastName'
                                placeholder={lastNamePlaceholder}
                                validator={new ValueSetValidator(this.validator)}
                            />
                        </InputContainer>
                        <InputContainer>
                            <Label htmlFor="emailAddress">Email *</Label>
                            <TextField
                                id='emailAddress'
                                placeholder={emailPlaceholder}
                                validator={new EmailValidator(this.validator)}
                            />
                        </InputContainer>
                        <InputContainer>
                            <Label htmlFor="telephoneNumber">Telephone Number *</Label>
                            <TextField
                                id='phoneNumber'
                                placeholder={telephonePlaceholder}
                                validator={new ValueSetValidator(this.validator)}
                            />
                        </InputContainer>
                        <InputContainer>
                            <Label htmlFor="companyName">Company Name *</Label>
                            <TextField
                                id='companyName'
                                placeholder={companyPlaceholder}
                                validator={new ValueSetValidator(this.validator)}
                            />
                        </InputContainer>
                        <InputContainer>
                            <Label htmlFor="subject">Subject *</Label>
                            <TextField
                                id='contactSubject'
                                placeholder={subjectPlaceholder}
                                validator={new ValueSetValidator(this.validator)}
                            />
                        </InputContainer>
                    </InputContainerHolder>
                    <MessageHolder>
                        <TextContainer>
                            <Label htmlFor="message">Message *</Label>
                            <TextAreaField validator={new ValueSetValidator(this.validator)}
                                id='messageCompany'
                                placeholder={messagePlaceholder}
                                className="form-control"
                            />
                        </TextContainer>
                    </MessageHolder>
                    <ButtonHolder>
                        <TextContainer>
                            <br />
                            <Button 
                                bgColor="defaultPrimary" 
                                bgHoverColor="defaultSecondary"
                                fontColor="defaultSecondary" 
                                fontHoverColor="defaultPrimary"
                                className={sendStatus} 
                                onClick={this.register} disabled={(['processing', 'done'].includes(sendStatus))}
                            >
                                {sendStatus === 'initial' && `${buttonTitle}`}
                                {sendStatus === 'processing' && <div>Submitting</div>}
                                {sendStatus === 'done' && 'Done!'}
                            </Button>
                        </TextContainer>
                    </ButtonHolder>
                </Form>
            </React.Fragment>
        );
    }
}

export default ContactForm;