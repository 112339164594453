export const BASE_URL = 'https://cr38te.com/';
export const ENDPOINT = 'cr38te/v1';
export default class ServerAPI {

    constructor(locale = '') {
        this.locale = locale !== '' ? `${locale}/` : '';
    }

    handleResponse = async (response) => {
        const result = await response.json();
        if (result.type === 'error') {
            let error = new Error(result.errorMessage)
            error.code = result.errorCode;
            throw error;
        }
        else {
            return result.data;
        }
    }

    getObjectType = async (type = '', options = {}) => {
        const perPage = options.perPage || -1;
        const offset = (options.page || 0) * perPage;
        let query = `offset=${offset}&posts_per_page=${perPage}`;
        if (options.id) query += `&p=${options.id}`;
        if (options.slug) query += `&name=${options.slug}`;
        if (options.category) query += `&cat=${options.category}`;
        if (options.order) query += `&orderby=${options.order.by}&order=${options.order.sort}`;
        if (options.excludeID) query += `&post__not_in[]=${options.excludeID}`;
        if (options.includeIDs) query += `${options.includeIDs.map(id => `&post__in[]=${id}`)}&orderby=post__in`;
        query += options.year ? `&year=${options.year}` : '';
        const response = await fetch(`${BASE_URL}${this.locale}wp-json/cr38te/v1/object/${type}?${query}`);
        return this.handleResponse(response);
    }

    getPostJSONData = async (type = '', slug = '', parent='false') => {
        const response = await fetch(`${BASE_URL}wp-json/${ENDPOINT}/object-data/${type}/${parent}/${slug}`);
        return this.handleResponse(response);
    }
}