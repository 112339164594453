import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display:grid;
    grid-template-columns:1fr 2fr 1fr;

    @media(max-width:786px) {
        grid-template-columns:15px 2fr 15px;
    }

    &.vacancy {
        grid-template-columns:1fr 1fr 1fr;
        h2, h3, h4 {
            margin-top:${props => props.theme.margin.doubleInset};
            margin-bottom:${props => props.theme.margin.inset};
        }
        h2, h3, h4,
        p, 
        ul > li {
            text-align:left;
            text-align-last:left;
        }
        ul {
            padding-left:15px;
        }
        a {
            color:#000;
            font-weight:bold;
            text-decoration:underline;
        }

        @media(max-width:480px) {
            grid-template-columns: 15px 1fr 15px;
        }
    }
`;

const Paragraph = styled.div`
    grid-column:2/3;
    text-align:${props => [props.alignText]};
    text-align-last:center;
    font-size:${props => props.theme.fontSizes.small};
    line-height:28px;
    font-weight: 400;
    letter-spacing: .05em;
`;

export default function ParagraphSection({ description, alignText, className }) {
    return (
        <Container className={className}>
            <Paragraph alignText={alignText} dangerouslySetInnerHTML={{ __html: `${description}` }} />
        </Container>
    );
}