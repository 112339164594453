import React, { Component } from 'react';
import styled from 'styled-components';
import PreLoader from '../components/preloader';
import ViewMorePortfolio from '../components/sub-components/view-more-portfolio';
import ContentBlocks from '../components/content-blocks';

const Main = styled.main`
	display:grid;
	grid-template-columns:1fr;
    margin-top:74px;
	align-items:end;
    margin-bottom:${props => props.theme.margin.tripleInset};

	@media (max-width:670px) {
		margin-top:55px;
	}
`;

const HeroArea = styled.div`
    display:grid;
    grid-template-columns:1fr;
    align-items:${props => [props.verticalAlign]};
    text-align:${props => [props.horizontalAlign]};
    width:100%;
    background-image:url(${props => [props.bgImage]});
    background-position:center center;
    background-repeat:no-repeat;
    background-size:cover;
    background-attachment:fixed;
    height: calc( ${props => [props.height]} - 74px );
    min-height:550px;

    @media (max-width:670px) {
        background-position:center center;
    }
    @media(max-width:480px) {
        background-position:center center;
        background-size:auto;
        background-attachment:scroll;
    }
`;

const Title = styled.h1`
    color: ${props => props.theme.colors[props.fontColor]};
    text-transform:uppercase;
    line-height: 52px;
    font-size: 51px;
    font-family:${props => props.theme.fonts.firstFont};
    padding-top:90px;
    padding-bottom:90px;
    padding-left:90px;
    padding-right:90px;
    margin-bottom:0px;

    @media(max-width:480px) {
        font-size:30px;
        line-height:32px;
        padding-top:30px;
        padding-bottom:30px;
        padding-left:30px;
        padding-right:30px;
    }
`;

const LogoArea = styled.div`
    display:grid;
    grid-template-columns:150px 1fr 150px;
    margin-top:${props => props.theme.margin.tripleInset};

    @media(max-width:480px) {
        grid-template-columns:15px 1fr 15px;
    }
`;

const Logo = styled.img`
    grid-column:2/3;
`;

const ServicesArea = styled.div`
    display:grid;
    grid-template-columns:150px 1fr 150px;
    margin-top:${props => props.theme.margin.tripleInset};

    @media(max-width:480px) {
        grid-template-columns:15px 1fr 15px;
    }
`;

const SubTitle = styled.h2`
    grid-column:2/3;
    text-transform:uppercase;
    font-family:${props => props.theme.fonts.firstFont};
    margin-bottom:${props => props.theme.margin.inset};
`;

const List = styled.ul`
    grid-column:2/3;
    margin:0;
    padding:0;
    list-style-type:none;
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
`;

const Item = styled.li`
    font-size:12px;
    line-height:24px;
    background-color:${props => props.theme.colors.defaultPrimary};
    color:${props => props.theme.colors.defaultSecondary};
    padding:8px;
    margin-right:5px;
    margin-bottom:5px;
`;

export default class WorkSingle extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fetching: true,
            isMobile: window.innerWidth < 480
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleWindowResize);
        this.getProfileData();
    }

    componentDidUpdate(prev) {
        if (prev.match?.params?.slug !== this.props.match?.params?.slug) {
            this.setState({ fetching: true, });
            this.getProfileData()
        }
    }

    getProfileData() {
        try {
            console.log('getting data...')
            const self = this;
            this.props.serverAPI.getPostJSONData('portfolio', this.props.match.params.slug)
                .then(data => self.setState({ ...data, fetching: false }));
        }
        catch (error) {
            this.setState({ error });
            console.log(error);
        }
    }

    handleWindowResize = () => {
        this.setState({ isMobile: window.innerWidth < 480 });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowResize);
    }

    render() {
        if (this.state.fetching) return <PreLoader />;

        let { serverAPI } = this.props;
        let { ID, acf, isMobile } = this.state;
        const { contentBlocks, generalInfoGroup, } = acf;
        const imageUrl = isMobile ? generalInfoGroup.mobileBackgroundImage.url : generalInfoGroup.backgroundImage.url

        return (
            <Main>
                <HeroArea
                    height={generalInfoGroup?.backgroundImageHeight}
                    bgImage={imageUrl}
                    verticalAlign={generalInfoGroup?.verticalAlign}
                    horizontalAlign={generalInfoGroup?.horizontalAlign}
                >
                    <Title fontColor="defaultSecondary">{generalInfoGroup?.subTitle}</Title>
                </HeroArea>
                <LogoArea>
                    <Logo src={generalInfoGroup?.logo.url} alt={generalInfoGroup?.clientName} />
                </LogoArea>
                <ServicesArea>
                    <SubTitle>{generalInfoGroup?.serviceTitle}</SubTitle>
                    <List>
                        {
                            generalInfoGroup?.servicesTags &&
                            generalInfoGroup?.servicesTags.map(({ title }, i) =>
                                <Item key={`item-${i}`}>{title}</Item>
                            )
                        }
                    </List>
                </ServicesArea>
                <ContentBlocks contentBlocks={contentBlocks} />
                <ViewMorePortfolio serverAPI={serverAPI} ID={ID} />
            </Main>
        );
    }
}