import React from 'react';
import styled from 'styled-components';
import ContactForm from './contact-form';
import ContactInfo from './contact-info';
import SocialMedia from './social-media';

const Container = styled.div`
    display:grid;
    grid-template-columns:75px 3fr 500px;

    @media (max-width:1024px) {
        grid-template-columns:30px 2fr 375px 30px;
    }

    @media (max-width:670px) {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
    }
`;
const ContactInfoContainer = styled.div``;

const ContactContainer = styled.div`
    grid-column:3/4;
    background-color:${props => props.theme.colors[props.bgColor]};
    background-image:url(${props => [props.bgImage]});
    background-position:center center;
    background-repeat:no-repeat;
    background-size:cover;
    padding:${props => props.theme.padding.tripleInset};
    position:relative;
    display:grid;
    align-items:start;

    @media (max-width:1024px) {
        padding:30px;
    }

    @media (max-width:480px) {
        grid-row: 2/3;
        grid-column: 1/-1;
        padding:60px 15px;
    }
`;

export const Title = styled.h3`
    color:${props => props.theme.colors[props.fontColor]};
    font-size: 32px;
    text-transform: uppercase;
    letter-spacing: 1px;
`;

export const Paragragh = styled.div`
    color:${props => props.theme.colors[props.fontColor]};
    font-family:${props => props.theme.fonts.secondFont};
`;

export const Link = styled.a`
    color:${props => props.theme.colors.defaultSecondary};
    font-family:${props => props.theme.fonts.secondFont};
    text-decoration:none;
`;

export const List = styled.ul`
    list-style-type:none;
    padding-left:0;

    &.social-media {
        display:grid;
        grid-template-columns:repeat(5, 1fr);
        li {
            
        }
    }
`;

export const Item = styled.li`
    color:${props => props.theme.colors[props.fontColor]};
    font-family:${props => props.theme.fonts.secondFont};
    display:grid;
    grid-template-columns:20px 1fr;
    grid-column-gap:${props => props.theme.padding.inset};
    margin-bottom:${props => props.theme.margin.inset};
`;

export default function ContactInfoSection({ contactFormGroup, contactInfoGroup, socialMediaGroup }) {
    return (
        <Container>
            <ContactForm {...contactFormGroup} />
            <ContactContainer
                bgColor="defaultPrimary"
            // bgImage={contactInfoGroup.backgroundImage.sizes.large}
            >
                <ContactInfoContainer>
                    <ContactInfo {...contactInfoGroup} />
                    <SocialMedia {...socialMediaGroup} />
                </ContactInfoContainer>
            </ContactContainer>
        </Container>
    )
}