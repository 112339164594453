import React from 'react';
import styled from 'styled-components';

const Block = styled.div`
    grid-column:${props => props.positionCol === 'left' ? '2/3' : '3/4'};
    align-self:${props => [props.verticalAlign]};
    justify-self:${props => [props.horizontalAlign]};

    @media(max-width:480px) {
        grid-column:2/3;
    }
`;

const ContentImage = styled.img`
    display:block;
    max-width:100%;

    @media(max-width:480px) {
        margin-bottom:15px;
    }
`;

export default function DuoColumnImageItem({
    verticalAlign,
    horizontalAlign,
    singleImage = {},
    positionCol,
}) {
    return (
        <Block positionCol={positionCol} verticalAlign={verticalAlign} horizontalAlign={horizontalAlign}>
            <ContentImage src={singleImage?.sizes?.fullscreen} alt={singleImage?.alt} />
        </Block>
    )
}