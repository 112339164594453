import React, { Component } from 'react';
import styled from 'styled-components';
import PortfolioSingleItem from '../sub-components/portfolioSingleItem';
// import PreviousIcon from '../../icons/prev';
// import NextIcon from '../../icons/next';

const Container = styled.div`
	display:grid;
    display:grid;
    grid-template-columns:15% 1fr 15%;

    @media(max-width:786px) {
        grid-template-columns:15px 1fr 15px;
    }
`;

const Title = styled.h2`
    grid-column:2/3;
    text-align:${props => [props.alignText]};
    padding-top:${props => props.theme.padding.tripleInset};
    padding-bottom:${props => props.theme.padding.tripleInset};
    font-size:${props => props.theme.fontSizes.large};
    text-transform:uppercase;

    @media(max-width:786px) {
        margin-top:${props => props.theme.margin.doubleInset};
        padding-top:${props => props.theme.padding.doubleInset};
        padding-bottom:${props => props.theme.padding.doubleInset};
    }
`;

const WorkContainer = styled.div`
    grid-column:2/3;
    text-align:justify;
    display:grid;
    grid-template-columns: repeat(${props => [props.repeatItems]}, calc(50% - 40px));
    grid-column-gap:15px;
    overflow-x:scroll;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;  

    @media(max-width:900px) {
        grid-template-columns: repeat(${props => [props.repeatItems]}, calc(100% - 40px));
    }
    
    @media(max-width:786px) {
        margin-top:0px;
        padding-left:15px;
    }
    > a {
        scroll-snap-align: center;
    }
`;

export default class ViewMorePortfolio extends Component {
    constructor(props) {
        super(props);
        this.state = { fetching: true };
    }

    async componentDidMount() {
        const ID = this.props.ID;
        try {
            let content = await this.props.serverAPI.getObjectType('portfolio', { excludeID: ID, order: { by: 'rand', sort: 'ASC' } });
            this.setState({ content, fetching: false });
        }
        catch (error) {
            this.setState({ error });
        }
    }

    render() {
        let { serverAPI, } = this.props;
        let { content, fetching } = this.state;
        if (fetching) return null;
        return (
            <Container>
                <Title alignText={`center`}>View More</Title>
                <WorkContainer className="workContainer" repeatItems={content.length}>
                    {content && content.map((workItem, i) => <PortfolioSingleItem key={`portfolio-${i}`} {...workItem} serverAPI={serverAPI} />)}
                </WorkContainer>
            </Container>
        );
    }
}