import React from 'react';
import styled from 'styled-components';
import DuoColumnAreaImage from './duoColumnImage';
import DuoColumnAreaParagraph from './duoColumnParagraph';
import DuoColumnAreaHostedVideo from './duoColumnHostedVideo';
import DuoColumnAreaYTVideo from './duoColumnYTVideo';
import DuoColumnAreaParagraphLinks from './duoColumnParagraphLinks';

const duoContentBlockTemplates = {
    'image': DuoColumnAreaImage,
    'paragraph': DuoColumnAreaParagraph,
    'hostedVideo': DuoColumnAreaHostedVideo,
    'youtubeVideo': DuoColumnAreaYTVideo,
    'paragraphWithLinks': DuoColumnAreaParagraphLinks,
}

const DuoColumnArea = styled.div`
    display:grid;
    grid-template-columns:150px 1fr 1fr 150px;
    height:${props => [props.height]};
    grid-column-gap:${props => props.theme.margin.inset};
    background-color:${props => [props.bgColor]};
    background-image:url('${props => [props.backgroundImg]}');
    background-position:center left;
    background-size:100%;
    background-repeat:no-repeat;
    margin-bottom:calc( ${props => props.theme.margin.tripleInset} * 2 );
    margin-top:calc( ${props => props.theme.margin.tripleInset} * 2 );

    @media(max-width:480px) {
        grid-template-columns:15px 1fr 15px;
        margin-top:${props => props.theme.margin.inset};
        margin-bottom:${props => props.theme.margin.inset};
        grid-column-gap:0;
        height:auto;
    }
`;

export default function DuoContent({ backgroundImage, insertHeight, backgroundColor, leftColumn, rightColumn, }) {
    return (
        <DuoColumnArea backgroundImg={backgroundImage?.url} bgColor={backgroundColor} height={`${insertHeight}px`}>
            {leftColumn?.length > 0 && leftColumn.map(({ acf_fc_layout, ...props }, i) => {
                const Template = duoContentBlockTemplates[acf_fc_layout];
                return (
                    <Template key={`left-duo-section-${i}`} positionCol="left" {...props} />
                )
            })}
            {rightColumn?.length > 0 && rightColumn.map(({ acf_fc_layout, ...props }, i) => {
                const Template = duoContentBlockTemplates[acf_fc_layout];
                return (
                    <Template key={`left-duo-section-${i}`} positionCol="right" {...props} />
                )
            })}
        </DuoColumnArea>
    )
}