import React from 'react';
import styled from 'styled-components';
import FacebookIcon from '../../icons/facebook';
import InstagramIcon from '../../icons/instagram';
import LinkedinIcon from '../../icons/linkedin';
import TwitterIcon from '../../icons/twitter';

const Modal = styled.div`
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100vh;
    background-color: ${props => props.theme.colors[props.bgColor]};
    z-index:9999;
    display:grid;
    grid-template-columns:1fr 1fr;

    @media only screen 
    and (min-device-width: 834px) 
    and (max-device-width: 834px) 
    and (orientation: portrait) 
    and (-webkit-min-device-pixel-ratio: 2),
    only screen 
    and (min-device-width: 768px) 
    and (max-device-width: 1024px) 
    and (orientation: portrait) 
    and (-webkit-min-device-pixel-ratio: 1) {
        grid-template-columns:1fr;
        grid-template-rows:calc( 100vw - 30px ), 1fr;
        height:100vh;
        overflow-y:scroll;
    }

    @media(max-width:480px) {
        grid-template-columns:1fr;
        grid-template-rows:calc( 100vw - 30px ), 1fr;
        height:100vh;
        overflow-y:scroll;
    }
`;

const ProfileImage = styled.div`
    grid-column:1/2;

    @media(max-width:1024px) {
        width: 55vw;
        height: 100vh;
    }

    @media only screen 
    and (min-device-width: 834px) 
    and (max-device-width: 834px) 
    and (orientation: portrait) 
    and (-webkit-min-device-pixel-ratio: 2),
    only screen 
    and (min-device-width: 768px) 
    and (max-device-width: 1024px) 
    and (orientation: portrait) 
    and (-webkit-min-device-pixel-ratio: 1)  {
        grid-column:1/-1;
        grid-row:1/2;
        width:100vw;
        height:70vw;
    }

    @media(max-width:480px) {
        grid-column:1/-1;
        grid-row:1/2;
        width:100vw;
        height:calc( 100vw - 30px );
    }
`;

const Biography = styled.div`
    grid-column:2/3;
    height:100%;
    padding:120px;

    @media(max-width:1024px) {
        padding:30px;
        height:100vh;
        overflow-y:scroll;
    }

    @media only screen 
    and (min-device-width: 834px) 
    and (max-device-width: 834px) 
    and (orientation: portrait) 
    and (-webkit-min-device-pixel-ratio: 2),
    only screen 
    and (min-device-width: 768px) 
    and (max-device-width: 1024px) 
    and (orientation: portrait) 
    and (-webkit-min-device-pixel-ratio: 1)  {
        grid-column:1/-1;
        grid-row:2/3;
        padding:30px;
        overflow:hidden;
        height:100%;
    }

    @media(max-width:480px) {
        grid-column:1/-1;
        grid-row:2/3;
        padding:30px;
        overflow:hidden;
        height:100%;
    }
`;

const Name = styled.h2`
    font-size:32px;
    margin-top:${props => props.theme.margin.doubleInset};
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom:5px;
`;

const Paragraph = styled.p`
    font-size: ${props => props.theme.fontSizes.small};
    line-height: 24px;
    letter-spacing: .05em;
`;

const Position = styled.p`
    margin-bottom:3rem;
    font-size: ${props => props.theme.fontSizes.small};
    line-height: 24px;
    letter-spacing: .05em;
`;

const List = styled.ul`
    list-style-type:none;
    padding:0;
    display:flex;
    justify-content:flex-start;
    margin-top:0;
    margin-bottom:${props => props.theme.margin.doubleInset};
`;

const Item = styled.li`
    margin-left:0px;
    margin-right:14px;      
`;

const Link = styled.a``;

const Icons = {
    "facebook-f": <FacebookIcon iconColor="defaultPrimary" />,
    "instagram": <InstagramIcon iconColor="defaultPrimary" />,
    "linkedin": <LinkedinIcon iconColor="defaultPrimary" />,
    "twitter": <TwitterIcon iconColor="defaultPrimary" />,
}
const ModalSpeaker = ({
    fullName,
    hoverImage,
    jobTitle,
    biography,
    socialMedia,
}) => {
    return (
        <Modal
            bgColor="defaultSecondary"
        >
            <ProfileImage style={{
                backgroundImage: `url(${hoverImage.url})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center'
            }}>
            </ProfileImage>
            <Biography>
                <Name>{fullName}</Name>
                <Position>{jobTitle}</Position>
                <Paragraph dangerouslySetInnerHTML={{ __html: `${biography}` }} />
                <List>
                    {
                        socialMedia &&
                        socialMedia.map(({ title, type, link }, i) =>
                            <Item key={i}>
                                <Link href={link} target="_blank" title={title}>
                                    {Icons[type]}
                                </Link>
                            </Item>
                        )
                    }
                </List>
            </Biography>
        </Modal>
    );
}
export default ModalSpeaker;