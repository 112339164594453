import React, { Component } from 'react';
import { Route } from 'react-router';
import HomePage from '../../pages/home';
import PortfolioOverview from '../../pages/work';
import AboutPage from '../../pages/about';
import TeamPage from '../../pages/team';
import ContactPage from '../../pages/contact';
import PreLoader from '../preloader/';

const components = {
    'index': HomePage,
    'work.php': PortfolioOverview,
    'about.php': AboutPage,
    'team.php': TeamPage,
    'contact.php': ContactPage,
}

class Page extends Component {
    state = {
        loading: true
    };

    async componentDidMount() {
        const title = this.props.post_name || 'page';
        this.setState({ loading: true });
        try {
            let content = await this.props.serverAPI.getPostJSONData('page', title, 'false');
            this.setState({ content, error: undefined, loading: false });
        }
        catch (error) {
            this.setState({ error });
        }
    }

    render() {
        const {
            loading,
            content = {},
        } = this.state;
        if (loading) {
            return <PreLoader />;
        }
        // else if (error) {
        //     return <PageError error={error} />;
        // }
        else {
            let { template } = content;

            let Template = components[template];
            if (!Template) Template = components['index'];
            return (
                <Template {...content} {...this.props} />
            )
        }
    }
}
export default Page;

export const getPagePath = (page) => {
    let path = `/${page.post_name || 'unkown'}`;
    return (path === '/home') ? '/' : `${path}**`;
}

export const PageRoutes = ({ website, serverAPI }) => {
    let { pages = [], contactInfo } = website;
    let pageRoutes = pages.map((page, index) => {
        const path = getPagePath(page);
        return (
            <Route key={index} path={path} exact render={(routerProps) =>
                <Page {...routerProps} {...page} contactInfo={contactInfo} serverAPI={serverAPI} />
            } />
        );
    });
    return pageRoutes;
}