import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import NavBarIcon from '../icons/navbar';

const Nav = styled.nav`
    display:grid;
    grid-template-columns:1fr;
    justify-items:end;
`;

const HamburgerMenu = styled.div`
    display:none;
    cursor:pointer;

    @media (max-width:670px) {
        display:block;
    }
`;

const List = styled.ul`
    margin:0;
    padding:0;
    display:grid;
    grid-template-columns:repeat(5, auto);
    grid-column-gap:0;
    list-style:none;

    @media (max-width:670px) {
        background-color:${props => props.theme.colors.defaultPrimary};
        display:block;
        position:absolute;
        top:55px;
        right:${props => props.theme.margin.inset};
        opacity:${({ isOpen }) => isOpen ? 1 : 0};
        visibility:${({ isOpen }) => isOpen ? 'visible' : 'hidden'};
    }
`;

const ListItem = styled.li``;

const ListLink = styled(NavLink)`
    text-decoration:none;
    color:${props => props.theme.colors.defaultSecondary};
    font-size:13px;
    text-transform:uppercase;
    text-align:center;
    display:block;
    padding:${props => props.theme.padding.inset};
    transition:all 0.3s linear;

    &:hover,
    &.active {
        background-color:${props => props.theme.colors.defaultSecondary};
        color:${props => props.theme.colors.defaultPrimary};
    }

    @media (max-width:670px) {
        text-align:left;
        width:125px;
    }
`;

const MainNavigation = () => {
    const [isOpen, setOpen] = useState();

    const onWindowClick = function (e) {
        if (document.getElementById('open-nav').contains(e.target)) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    }

    useEffect(() => {
        window.addEventListener('click', onWindowClick);
        return () => {
            window.removeEventListener('click', onWindowClick);
        }
    });

    return (
        <Nav>
            <HamburgerMenu id="open-nav">
                <NavBarIcon iconColor="defaultSecondary" />
            </HamburgerMenu>
            <List isOpen={isOpen}>
                <ListItem>
                    <ListLink exact title="Home" to="/" activeClassName="active">Home</ListLink>
                </ListItem>
                <ListItem>
                    <ListLink exact title="Work" to="/work">Work</ListLink>
                </ListItem>
                <ListItem>
                    <ListLink exact title="About" to="/about">About</ListLink>
                </ListItem>
                <ListItem>
                    <ListLink exact title="Team" to="/team">Team</ListLink>
                </ListItem>
                <ListItem>
                    <ListLink exact title="Contact" to="/contact">Contact</ListLink>
                </ListItem>
            </List>
        </Nav>
    );
}
export default MainNavigation;