import React from 'react';
import styled from 'styled-components';
import Button from '../button';
import DefineIcon from '../../icons/define.svg';
import DesignIcon from '../../icons/design.svg';
import DevelopIcon from '../../icons/develop.svg';

const Container = styled.div`
    display:grid;
    grid-template-rows:3;
    padding-top:120px;
    padding-bottom:120px;
`;

const Title = styled.h2`
    font-size:${props => props.theme.fontSizes.large};
    margin-bottom:0;
    grid-column: 1 / -1;
    text-align:center;
    line-height:110%;
    text-transform:uppercase;
`;

const ServicesBlock = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 320px);
    justify-content:center;
    grid-column-gap:${props => props.theme.margin.inset};
    margin-top: ${props => props.theme.margin.tripleInset};
    margin-bottom: ${props => props.theme.margin.tripleInset};
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;  

    @media(max-width:875px) {
        grid-column-gap:${props => props.theme.margin.inset};
        grid-template-columns: repeat(3, 273px);
        overflow-x:scroll;
        justify-content: start;
        margin-left:25px;
        margin-right:25px;
    }
    @media(max-width:480px) {
        grid-template-columns: repeat(3, calc( 100vw - 85px ) );
    }
`;

const Service = styled.div`
    height:273px;
    padding:${props => props.theme.margin.doubleInset};
    transition:all 0.3s linear;
    background-color:${props => props.theme.colors.defaultPrimary};
    border: 7px solid ${props => props.theme.colors.defaultPrimary};
    scroll-snap-align: start;

    &:hover {
        background-color:${props => props.theme.colors.defaultSecondary};
        img {
            filter: brightness(0%);
        }
        h3, p {
            color:${props => props.theme.colors.defaultPrimary};
        }
        p {
            display:none;
        }
        ul {
            display:block;
            padding-left:40px;
            li {
                color:${props => props.theme.colors.defaultPrimary};
            }
        }
    }

    @media(max-width:480px) {
        height:calc( 100vw - 85px );
    }
`;

const Icon = styled.img`
    margin-right:4px;
    font-size:10px;
    width:25px;
    height:25px;
    filter:invert(100%) sepia(100%) saturate(100%) hue-rotate(86deg) brightness(100%) contrast(100%);
`;

const Content = styled.div``;

const SubTitle = styled.h3`
    color:${props => props.theme.colors.defaultSecondary};
    font-size:${props => props.theme.fontSizes.medium};
    text-transform:uppercase;
`;

const Paragrah = styled.p`
    color:${props => props.theme.colors.defaultSecondary};
    margin:0 auto;
    padding-left:40px;
`;

const List = styled.ul`
    display:none;
    margin:0;
    padding:0;
    list-style:square circle;
    list-style-position: inside;
    padding-left:${props => props.theme.padding.inset};
    color:${props => props.theme.colors.defaultSecondary};
`;

const ListItem = styled.li`
    margin-bottom:2px;
`;

const NewButton = styled(Button)`
    grid-column:1 / -1;
    width:195px;
    justify-self:center;
`;

const Icons = {
    "define" : <Icon src={DefineIcon} alt="Define icon - CR38TE - DEFINE - DESIGN - DEVELOP - ARUBA"/>,
    "design" : <Icon src={DesignIcon} alt="Design icon - CR38TE - DEFINE - DESIGN - DEVELOP - ARUBA" />,
    "develop": <Icon src={DevelopIcon} alt="Develop icon - CR38TE - DEFINE - DESIGN - DEVELOP - ARUBA" />,
}

const ServiceArea = ({ 
    mainTitle, 
    serviceBlock,
    buttonTitle,
    buttonLink,
 }) => {
    return (
        <Container>
            <Title>{mainTitle}</Title>
            <ServicesBlock>
                {serviceBlock && serviceBlock.map(({title, description, bulletPoints, type}, i) =>
                    <Service key={i} className={`block-${i}`}>
                        <Content>
                            <SubTitle>{Icons[type]} {title}</SubTitle>
                            <Paragrah dangerouslySetInnerHTML={{ __html: `${description}` }} />
                            <List>
                                {bulletPoints && bulletPoints.map(({bulletDescription}, i) => 
                                    <ListItem key={i}>{bulletDescription}</ListItem>
                                )}
                            </List>
                        </Content>
                    </Service>
                )}
            </ServicesBlock>
                {
                    buttonTitle &&
                    <NewButton 
                        buttonLink={buttonLink} 
                        bgcolor="defaultPrimary"
                        bgcolorhover="defaultSecondary"
                        fontcolor="defaultSecondary"
                        fontcolorhover="defaultPrimary"
                        buttonTarget="_self"
                        buttonText={buttonTitle}
                    />
                }
        </Container>
    );
}
export default ServiceArea;