import React from 'react';
import styled from 'styled-components';

const LogoContainer = styled.div`
	display:grid;
	grid-template-columns:1fr 3fr 1fr;
	margin-top:${props => props.theme.margin.tripleInset};
	margin-bottom:120px;
    margin-top:120px;
	
	@media(max-width:1140px) {
		grid-template-columns:15px 1fr 15px;
	}
`;

const Title = styled.h2`
	grid-column:2/3;
	color: ${props => props.theme.colors.defaultPrimary};
    text-transform:uppercase;
    line-height: 52px;
    font-size: 51px;
    font-family:${props => props.theme.fonts.firstFont};
    margin-bottom:calc( ${props => props.theme.margin.tripleInset} * 2 );
	text-align:center;

	@media(max-width:480px) {
		font-size: 32px;
	}
`;

const LogoHolder = styled.div`
	grid-column:2/3;
	display:grid;
	grid-template-columns:repeat(4, 1fr);
	align-items:center;
	justify-content:center;

	@media(max-width:480px) {
		grid-template-columns:repeat(2, 1fr);
	}
`;

const Logo = styled.img`
	display:block;
	max-width:100%;
	margin:0 auto 15px auto;
`;

export default function Logos({ title, logos }) {
    return (
        <LogoContainer>
            <Title>{title}</Title>
            <LogoHolder>
                {logos?.length > 0 && logos.map(({ acf, }, i) =>
                    <Logo
                        key={`partners-${i}`}
                        src={acf.clientLogo.sizes.fullscreen}
                        alt={acf.clientLogo.alt || acf.clientLogo.name}
                    />
                )}
            </LogoHolder>
        </LogoContainer>
    );
}