import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display:grid;
    grid-template-columns:1fr 1fr 2fr 1fr;
    margin-bottom:${props => props.theme.margin.tripleInset};
    grid-column-gap:15px;

    @media(max-width:786px) {
        grid-template-columns:15px 150px 2fr 15px;
        grid-column-gap:0;
    }
`;

const SubContainer = styled.div`
    grid-column:2/4;
    display:grid;
    grid-template-columns:1fr 3fr;
    margin-bottom:${props => props.theme.margin.tripleInset};

    @media(max-width:480px) {
        grid-template-columns:125px 1fr;
    }
`;

const TitleContainer = styled.div`
    grid-column:1/2;
    margin-top:5px;
`;

const Title = styled.h3`
    font-size: ${props => props.theme.fontSizes.small};
    line-height:28px;
    text-transform: uppercase;
    letter-spacing: 1px;
    position:sticky;
    top:100px;

    @media(max-width:786px) {
        margin-top:0px;
        padding-right:0;
    }
`;

const Paragraph = styled.div`
    grid-column:2/3;
    text-align:justify;
    line-height:28px;

    @media(max-width:786px) {
        margin-top:0px;
        padding-left:15px;
    }
`;

export default function DuoContentSection({ content_block }) {
    return (
        <Container>
            {content_block && content_block.map(({ title, description }, i) =>
                <SubContainer key={`content-block-${i}`}>
                    <TitleContainer>
                        <Title>{title}</Title>
                    </TitleContainer>
                    <Paragraph dangerouslySetInnerHTML={{ __html: `${description}` }} />
                </SubContainer>
            )}
        </Container>
    );
}