import React from 'react';
import styled from 'styled-components';
import MapMarkerImage from '../../../icons/black_marker.png';
import GoogleMapReact from 'google-map-react';

const Container = styled.div`
    display:grid;
    grid-template-columns:75px 1fr 75px;
    padding-top:120px;
    padding-bottom:0;

    @media (max-width:1024px) {
		grid-template-columns: 15px 1fr 15px;
        padding-top:90px;
        padding-bottom:0;
	}
`;

const Title = styled.div`
    background-color:${props => props.theme.colors[props.bgColor]};
    color:${props => props.theme.colors[props.fontColor]};
    font-size:52px;
    font-weight:${props => props.theme.fontWeight.light};
    font-family:${props => props.theme.fonts.firstFont};
    margin-top:${props => props.theme.margin.doubleInset};
    margin-bottom:90px;
    justify-self: center;
    line-height:100%;
    width: auto;
    display: inline-block;
    overflow: hidden;
    padding: 14px 10px 0 10px;
    grid-column:2/3;

    @media(max-width:1024px) {
        font-size:35px;
        padding-top:0;
        line-height:52px;
        margin-bottom:30px;
        margin-top:0;
    }
`;

const CustomMap = styled.div`
    height:70vh;
    border-radius:0;
    margin:0 0 0;
    grid-column:1/-1;

    @media(max-width:1024px) {
        grid-column:1/-1;
        margin-bottom:0;
    }
`;

const AnyReactComponent = ({ text }) => <div>{text}</div>;

const mapOptions = {
    styles: [
        {
            "featureType": "all",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "saturation": 36
                },
                {
                    "color": "#000000"
                },
                {
                    "lightness": 40
                }
            ]
        },
        {
            "featureType": "all",
            "elementType": "labels.text.stroke",
            "stylers": [
                {
                    "visibility": "on"
                },
                {
                    "color": "#000000"
                },
                {
                    "lightness": 16
                }
            ]
        },
        {
            "featureType": "all",
            "elementType": "labels.icon",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "administrative",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#000000"
                },
                {
                    "lightness": 20
                }
            ]
        },
        {
            "featureType": "administrative",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "color": "#000000"
                },
                {
                    "lightness": 17
                },
                {
                    "weight": 1.2
                }
            ]
        },
        {
            "featureType": "landscape",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#000000"
                },
                {
                    "lightness": 20
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#000000"
                },
                {
                    "lightness": 21
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#000000"
                },
                {
                    "lightness": 17
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "color": "#000000"
                },
                {
                    "lightness": 29
                },
                {
                    "weight": 0.2
                }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#000000"
                },
                {
                    "lightness": 18
                }
            ]
        },
        {
            "featureType": "road.local",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#000000"
                },
                {
                    "lightness": 16
                }
            ]
        },
        {
            "featureType": "transit",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#000000"
                },
                {
                    "lightness": 19
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#000000"
                },
                {
                    "lightness": 17
                }
            ]
        }
    ]
};

const handleApiLoaded = (map, maps) => {
    // use map and maps objects
};

export default function GoogleMaps() {

    const defaultProps = {
        center: {
            lat: 12.5123946,
            lng: -70.0205195
        },
        zoom: 15,
        backgroundColor: '#dddddd',
        disableDoubleClickZoom: false,
        keyboardShortcuts: false,
        scrollwheel: false,
        draggable: true,
        panControl: true,
        arrowPosition: 30,
        arrowStyle: 2,
        arrowSize: 15,
        disableDefaultUI: true,
        zoomControl: true,
    };

    return (
        <Container>
            <Title fontColor='defaultPrimary' bgColor="defaultSecondary">HQ</Title>
            <CustomMap style={{ height: '100vh', width: '100%' }}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: 'AIzaSyAGD0xPpAX3PEo8AVa5fOcCPckc7JvhanU' }}
                    defaultCenter={defaultProps.center}
                    defaultZoom={defaultProps.zoom}
                    options={mapOptions}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                >
                    <AnyReactComponent
                        lat={12.51234621}
                        lng={-70.02053618}
                        text={<img src={MapMarkerImage} alt="CR38TE HQ - Anasastraat 4 - Dakota - Aruba" />}
                    />
                </GoogleMapReact>
            </CustomMap>
        </Container>
    );
}