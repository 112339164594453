import React from 'react';
import styled from 'styled-components';
import IconPhone from '../../../icons/phone';
import IconEnvelope from '../../../icons/envelope';
import IconHours from '../../../icons/hours';
import { Title, Paragragh, List, Item, Link} from './';

const ContactInfoContainer = styled.div`
    margin-bottom:${props => props.theme.margin.tripleInset};
    position:relative;
    z-index:2;

    &:last-child {
        margin-bottom:0;
    }
`;

const ImageOverlay = styled.div`
    z-index:1;
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-color:rgba(0,0,0,.5);
`;

export default function ContactInfo({ infoRepeater }) {
    return (
        <>
            <ImageOverlay className="img-overlay" />
            {infoRepeater?.length > 0 && infoRepeater.map(({ title, description, telephone, email, openingHours }, i) =>
                <ContactInfoContainer key={`contactinfo-${i}`}>
                    <Title fontColor="defaultSecondary">{title}</Title>
                    {description && <Paragragh fontColor="defaultSecondary">{description}</Paragragh>}
                    <List>
                        {telephone &&
                            <Item fontColor="defaultSecondary">
                                <IconPhone iconColor="defaultSecondary" />
                                <Link href={`tel:${telephone}`} target="_blank">{telephone}</Link>
                            </Item>
                        }
                        {email &&
                            <Item fontColor="defaultSecondary">
                                <IconEnvelope iconColor="defaultSecondary" />
                                <Link href={`mailto:${email}`} target="_blank">{email}</Link>
                            </Item>
                        }
                        {openingHours &&
                            <Item fontColor="defaultSecondary">
                                <IconHours iconColor="defaultSecondary" /> {openingHours}
                            </Item>
                        }
                    </List>
                </ContactInfoContainer>
            )}
        </>
    )
}