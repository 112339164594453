import React from 'react';
import styled from 'styled-components';

const FullColumnItem = styled.div`
    display:grid;
    grid-template-columns:1fr;
    align-items:${props => [props.verticalAlign]};
    text-align:${props => [props.horizontalAlign]};
    justify-items:${props => [props.horizontalAlign]};
    height:auto;
    background-color:${props => [props.bgColor]};

    @media(max-width:480px) {
        height:auto;
    }
`;

const DecoImage = styled.img`
    display:block;
    max-width:100%;
`;

export default function FullColumnBackgroundImageItem({
    backgroundColor,
    horizontalAlign,
    image,
    insertHeight,
    verticalAlign,
}) {
    return (
        <FullColumnItem
            verticalAlign={verticalAlign}
            horizontalAlign={horizontalAlign}
            height={`${insertHeight}px`}
            bgColor={backgroundColor}
        >
            <DecoImage src={image.sizes.fullscreen} alt={image.alt} />
        </FullColumnItem>
    )
}