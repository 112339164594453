import React, { useState } from 'react';
import styled from 'styled-components';
import TeamModal from '../../components/sub-components/team-modal';
import FacebookIcon from '../../icons/facebook';
import InstagramIcon from '../../icons/instagram';
import LinkedinIcon from '../../icons/linkedin';
import TwitterIcon from '../../icons/twitter';
import CloseIcon from '../../icons/close';


const Member = styled.div`
    position:relative;
    width: calc( calc( 100vw - 180px ) / 3 );
    margin-bottom:${props => props.theme.padding.inset};
    cursor:pointer;

    &:hover {
        .current-img {
            visibility:hidden;
            opacity:0;
        }
        .hover-img {
            visibility:visible;
            opacity:1;
        }
        .content {
            background-color:${props => props.theme.colors.defaultPrimary};
            h3, p,
            ul > li > a > div > svg {
                color:${props => props.theme.colors.defaultSecondary};
            }
        }
    }

    @media(max-width:736px) {
        width:calc( calc( 100vw - 60px ) / 3 );
    }

    @media(max-width:480px) {
        width:calc( 100vw - 30px );
    }
`;

const CurrentImage = styled.img`
    display: block;
    max-width: 100%;
    height: calc( calc( 100vw - 180px ) / 3 );

    @media(max-width:736px) {
        height:calc( calc( 100vw - 60px ) / 3);
    }

    @media(max-width:480px) {
        height:calc( 100vw - 30px );
    }
`;

const HoverImage = styled.img`
    opacity:0;
    position:absolute;
    top:0;
    left:0;
    width:100%;
    display: block;
    max-width: 100%;
`;

const Content = styled.div`
    display:grid;
    grid-template-columns:15px 1fr 15px;
    text-align:center;

    svg {
        width:15px;
        height:15px;
    }
`;

const Name = styled.h3`
    font-size:${props => props.theme.fonts.small};
    grid-column:2/3;
    margin-top:${props => props.theme.margin.doubleInset};
`;

const Paragraph = styled.p`
    grid-column:2/3;
`;

const List = styled.ul`
    grid-column:2/3;
    list-style-type:none;
    padding:0;
    display:flex;
    justify-content:center;
    margin-top:0;
    margin-bottom:${props => props.theme.margin.doubleInset};
`;

const Item = styled.li`
    margin-left:7px;
    margin-right:7px;      
`;

const Link = styled.a``;

const Button = styled.span`
    position: fixed;
	top: 15px;
	right: 15px;
	z-index: 99999;
    background-color:${props => props.theme.colors.defaultPrimary};
    color:${props => props.theme.colors.defaultSecondary};
	display:block;
    cursor: pointer;
    padding:12px 15px;
    font-size:16px;
`;

const Icons = {
    "facebook-f": <FacebookIcon iconColor="defaultPrimary" />,
    "instagram": <InstagramIcon iconColor="defaultPrimary" />,
    "linkedin": <LinkedinIcon iconColor="defaultPrimary" />,
    "twitter": <TwitterIcon iconColor="defaultPrimary" />,
}

export default function TeamMemberItem({ ID, acf }) {
    const { activeImage, biography, fullName, hoverImage, jobTitle, socialMedia, } = acf;
    const [showModal, setShowModal] = useState(false);
    const [type, setType] = useState(false);

    const openModal = (event, value) => {
        setShowModal(true);
        setType(value);
    };

    const closeModal = (event, value) => {
        event.stopPropagation();
        setShowModal(false);
        setType(value);
    };

    return (
        <Member onClick={event => openModal(event, ID)}>
            <CurrentImage className="current-img" src={activeImage.sizes.fullscreen} alt={fullName} />
            <HoverImage className="hover-img" src={hoverImage.sizes.fullscreen} alt={fullName} />
            <Content className="content">
                <Name>{fullName}</Name>
                <Paragraph>{jobTitle}</Paragraph>
                <List>
                    {
                        socialMedia &&
                        socialMedia.map(({ title, type, link }, i) =>
                            <Item key={i}>
                                <Link href={link} target="_blank" className="primary-color" title={title}>
                                    {Icons[type]}
                                </Link>
                            </Item>
                        )
                    }
                </List>
            </Content>
            {showModal &&
                <React.Fragment>
                    <Button onClick={closeModal}>
                        <CloseIcon iconColor="defaultSecondary" />
                    </Button>

                    {type === ID &&
                        <TeamModal
                            show={showModal}
                            close={closeModal}
                            fullName={fullName}
                            hoverImage={hoverImage}
                            jobTitle={jobTitle}
                            biography={biography}
                            socialMedia={socialMedia}
                        />
                    }
                </React.Fragment>
            }
        </Member>
    );
}