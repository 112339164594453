import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const WorkItem = styled(Link)`
    position:relative;
    background-color: ${props => props.theme.colors.defaultTertiary};
    margin-bottom:15px;

    &:hover {
        .overlay {
            background-color:${props => props.mode === 'dark' ? 'rgba(0,0,0,0.75)' : 'rgba(255,255,255,0.75)'};
        }
    }
`;

const Content = styled.div`
    position:absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 15px;
    z-index:15;
`;

const Image = styled.div`
    display:block;
    min-height:380px;
    background-position:center center;
    background-repeat:no-repeat;
    background-size:cover;
    background-image:url('${props => [props.backgroundImg]}');

    @media(max-width:1355px) {
        min-height:250px;
    }

    @media(max-width:480px) {
        min-height:200px;
    }
`;

const TitleClient = styled.h3`
    margin-bottom:0px;
    color: ${props => props.mode === 'dark' ? 'rgb(255,255,255)' : 'rgb(0,0,0)'};
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1px;
`;

const TitleProject = styled.h4`
    margin-bottom:0px;
    color: ${props => props.mode === 'dark' ? 'rgb(255,255,255)' : 'rgb(0,0,0)'};
    line-height:28px;
    font-size:28px;
    text-transform: uppercase;
    text-align:left;

    @media(max-width:900px) {
        font-size:18px;
    }
`;

const Overlay = styled.div`
    background-color:${props => props.mode === 'dark' ? 'rgba(0,0,0,0.25)' : 'rgba(255,255,255,0.25)'};
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    z-index:10;
    transition:all 0.3s linear;

    &:hover {
        background-color:${props => props.mode === 'dark' ? 'rgba(0,0,0,0.75)' : 'rgba(255,255,255,0.75)'};
    }
`;

const PortfolioSingleItem = ({ post_type, post_name, acf }) => {
    const { generalInfoGroup = {} } = acf;
    const { backgroundImage, clientName, mode, title } = generalInfoGroup;

    return (
        <WorkItem mode={mode} to={`/${post_type}/${post_name}`} title={`Go to ${clientName} - ${title}`}>
            <Image backgroundImg={backgroundImage.sizes.large} alt={title} />
            <Overlay className="overlay" mode={mode} />
            <Content>
                <TitleClient mode={mode}>{clientName}</TitleClient>
                <TitleProject mode={mode}>{title}</TitleProject>
            </Content>
        </WorkItem>
    );
}
export default PortfolioSingleItem;