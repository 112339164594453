import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { BASE_URL } from '../../server';

const Container = styled.div`
    display:grid;
    justify-content:center;
    grid-template-columns:repeat(2, 50%);

    @media (max-width: 1024px){
        grid-template-columns: repeat(1,100%);
    }
`;

const LinkContainer = styled.div`
  border-style: solid;
  border-color: white;
  &:nth-child(odd) {
    border-width: 14px 7px 14px 14px;
  }
  &:nth-child(even) {
    border-width: 14px 14px 14px 7px;
  }

  @media (max-width: 1024px){
      &:nth-child(odd) {
          border-width: 14px 14px 7px 14px;
      }
      &:nth-child(even) {
          border-width: 7px 14px 14px 14px;
      }
  }
`;

const Link = styled(NavLink)`
    text-decoration:none;
    display:block;
    color:${props => props.theme.colors.defaultSecondary};
    background: black;

    &:hover h3:after{
        width: 100%;
        left: 0;
        background: #fff;
    }

    &:hover  p {
        color:#000;
    }

    &:hover .call-to-action-mask {
        height:100%;
    }
`;

const Block = styled.div`
    display:flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 90px;
    background-color:${props => props.theme.colors.defaultPrimary};
    align-items: baseline;

    @media (max-width:1280px) {
        padding: 40px 60px;
    }

    @media (max-width:768px) {
        padding: 40px;
    }

    @media (max-width:480px){
        flex-direction: column;
        align-items: center;
        margin-top: 10px;
    }
`;

const Title = styled.h3`
    font-size: 48px;
    text-transform: uppercase;
    margin: 0px;

    &:after{
        content: '';
        width: 0;
        height: 3px;
        display: block;
        background: #fff;
        transition: width 0.4s ease-in-out;
    }
`;

const ParagraphContainer = styled.div`
    font-family: open-sans;
    position: relative;
    margin: 0px;
    font-size:${props => props.theme.fontSizes.small};
    transition:all 0.4s linear;

    @media (max-width:480px){
        align-self: center;
    }
`;

const Paragraph = styled.p`
    margin: 0px;
    transition:all 0.4s linear;
    position: relative;
    z-index: 10;
`;

const Mask = styled.div`
    bottom: 0;
    left: 0;
    position: absolute;
    background-color: #fff;
    width: 100%;
    height: 0%;
    transition:all 0.3s linear;
`;

export default function CallToActions({ callToActions }) {
    return (
        <Container>
            {callToActions?.length > 0 && callToActions.map(({ buttonLink, buttonText, title }, i) =>
                <LinkContainer key={`call-to-acttion-${i}`} className="link-container">
                    <Link to={buttonLink.replace(BASE_URL, '/') || '/'} title={`${title} - CR38TE - DEFINE - DESIGN - DEVELOP - ARUBA`}>
                        <Block className="link-text-block" >
                            <Title>{title}</Title>
                            <ParagraphContainer className="call-to-action-text">
                                <Paragraph>
                                    {buttonText}
                                </Paragraph>
                                <Mask className="call-to-action-mask" />
                            </ParagraphContainer>
                        </Block>
                    </Link>
                </LinkContainer>
            )}
        </Container>
    )
}