import React from 'react';
import styled from 'styled-components';

const Block = styled.div`
    grid-column:${props => props.positionCol === 'left' ? '2/3' : '3/4'};
    align-self:${props => [props.verticalAlign]};
    justify-self:${props => [props.horizontalAlign]};

    @media(max-width:480px) {
        grid-column:2/3;
    }
`;

const SectionParagraph = styled.p`
    grid-column:3/4;
    font-size:16px;
    font-family:${props => props.theme.fonts.secondFont};
    font-weight:${props => props.theme.fontWeight.thin};
    line-height:32px;
    margin-bottom:30px;
    width:500px;

    @media(max-width:480px) {
        width:100%;
        grid-column:2/3;
    }
`;

const List = styled.ul`
    grid-column:2/3;
    margin:0;
    padding:0;
    list-style-type:none;
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
`;

const ItemLink = styled.li`
    margin-right:15px;
    margin-bottom:15px;
`;

const HyperLink = styled.a`
    color:#5fb4e7;
    font-size:16px;
    font-family:${props => props.theme.fonts.secondFont};
    font-weight:${props => props.theme.fontWeight.thin};
    line-height:32px;
    text-decoration:none;
    cursor:pointer;

    &:hover {
        text-decoration:underline;
    }
`;

const DuoColumnParagraphLinksItem = ({
    verticalAlign,
    horizontalAlign,
    positionCol,
    sectionParagraph,
    hyperlinks
}) =>
    <Block
        positionCol={positionCol}
        verticalAlign={verticalAlign}
        horizontalAlign={horizontalAlign}
    >
        <SectionParagraph dangerouslySetInnerHTML={{ __html: `${sectionParagraph}` }} />
        <List>
            {
                hyperlinks &&
                hyperlinks.map(({ linkTitle, linkItem }, i) =>
                    <ItemLink key={`item-link-${i}`}>
                        <HyperLink target="_blank" href={linkItem}>{linkTitle}</HyperLink>
                    </ItemLink>
                )
            }
        </List>
    </Block>
export default DuoColumnParagraphLinksItem;