import React from 'react';
import styled from 'styled-components';
import { Title, Paragragh, List, Item, Link} from './';
import IconFacebook from '../../../icons/facebook';
import IconInstagram from '../../../icons/instagram';
import IconLinkedin from '../../../icons/linkedin';
import IconTwitter from '../../../icons/twitter';
import IconYoutube from '../../../icons/youtube';

const Icons = {
    "facebook": <IconFacebook iconColor="defaultSecondary" />,
    "twitter": <IconTwitter iconColor="defaultSecondary" />,
    "instagram": <IconInstagram iconColor="defaultSecondary" />,
    "youtube": <IconYoutube iconColor="defaultSecondary" />,
    "linkedin": <IconLinkedin iconColor="defaultSecondary" />,
}

const Container = styled.div`
    margin-bottom:${props => props.theme.margin.tripleInset};
    position:relative;
    z-index:2;

    &:last-child {
        margin-bottom:0;
    }
`

export default function SocialMedia({ title, description, socialMediaItem }) {
    return (
        <Container>
            <Title fontColor="defaultSecondary">{title}</Title>
            {description && <Paragragh fontColor="defaultSecondary">{description}</Paragragh>}
            <List className="social-media">
                {socialMediaItem?.length > 0 && socialMediaItem.map(({ type, title, link }, i) =>
                    <Item key={`socialmedialink-${i}`}>
                        <Link href={link} target="_blank" title={title}>
                            {Icons[type]}
                        </Link>
                    </Item>
                )}
            </List>
        </Container>
    )
}